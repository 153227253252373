.ballons {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: -35px;
        left: 30px;
        height: 110%;
        width: 100%;
        background: url('/assets/images/ballons-color.svg') no-repeat;
        background-size: 7%;
    }
}

@media only screen and (max-width: 600px) {
    .ballons {
        &:after{
            background-size: 28%;
        }
    }
}

.lamp {
        content: "";
        position: absolute;
        top: -15px;
        left: -6rem;
        height: 100%;
        width: 100%;
        background: url('/assets/images/lamp.svg') no-repeat;
    }


.fire {
    position: relative;
    overflow: hidden;
    background: linear-gradient(315deg, rgba(254,215,102,1) 0%, rgba(254,182,102,1) 100%);
    background-size: 5px 5000px;
    transition: all 0.5s ease;
    padding-right:2rem;
    &:after {
        content: "";
        position: absolute;
        top: -28px;
        height: 105px;
        width: 45px;
        background: url('/assets/images/fire.svg') no-repeat;
        background-size: 45px;
        left: 10px;
        transition: all 0.2s ease-in-out;
    }

    &:hover{
        background-position:-200px;  
        padding-right:3rem;
    &:after{
        left: 10px;
        
        background-size:47px;
    }
    }
}

