.rating-button{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:center;
    background: #FED766;
    border:0;
    padding:0;
    border-radius: 7px;
    min-height: 115px;
    overflow:hidden;
    text-align: center;
    min-width: 50px;
    transition: all 0.15s ease;

    .rating-top{
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.15s ease;
        text-align: center;
        font-size: 34px;
        flex-grow: 1;
        vertical-align: middle;
    }
    .rating-bottom{
        background: #FFEFC1;
        width:100%;
        transition: all 0.15s ease;
        text-align:center;
        font-size: 13px;
        padding-top:10px;
        padding-bottom:10px;
        .rating-footer{
            font-size: 12px;
            color: #959595;
            text-align: center;
        }
    }
    &:hover{
     .rating-top{
         padding-bottom: 0.2rem;
     } 
     .rating-bottom{
        background: #FED766;
     } 
    }

    &.rating-active{
        border: 3px solid #FEB666;
        opacity: 1 !important;
        .rating-bottom{
            background: #FED766;
         } 
    }

    &.rating-disabled{
        opacity:0.65;
    }
}