

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff;
}

.resp-sharing-button {
  border-radius: 0px;
  transition: 25ms ease-out;
  padding: 0.50em 0.65em;
}

.resp-sharing-button__icon svg {
  width: 1.5em;
  height: 1.5em;
  margin-left: 0.4em;
}

.resp-sharing-button--small svg {
  margin: 0;
}

/* Non solid icons get a stroke */
.resp-sharing-button__icon {
  stroke: #fff;
  fill: none
}

/* Solid icons get a fill */
.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
  fill: #fff;
  stroke: none
}

.resp-sharing-button--twitter {
  background-color: #1FA1F3
}

.resp-sharing-button--twitter:hover {
  background-color: #2795e9
}
.resp-sharing-button--telegram {
  border-top-right-radius: 25px;
  background-color: rgba(1,136,204,1)
}
.resp-sharing-button--telegram:hover {
  background-color: #1FA1F3
}



.resp-sharing-button--facebook {
  background-color: #1774EC
}

.resp-sharing-button--facebook:hover {
  background-color: #2d4373
}


.resp-sharing-button--whatsapp {
  background-color: #4AC659
}

.resp-sharing-button--whatsapp:hover {
  background-color: #1da851
}

.resp-sharing-button--vk {
  background-color: #507299
}

.resp-sharing-button--vk:hover {
  background-color: #43648c
}

.resp-sharing-button--facebook {
  background-color: #1774EC;
  border-color: #1774EC;
}

.resp-sharing-button--facebook:hover,
.resp-sharing-button--facebook:active {
  background-color: #2d4373;
  border-color: #2d4373;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
  border-color: #55acee;
}

.resp-sharing-button--twitter:hover,
.resp-sharing-button--twitter:active {
  background-color: #1FA1F3;
  border-color: #1FA1F3;
}

.resp-sharing-button--whatsapp {
  border-top-left-radius: 25px;
  background-color: #4AC659;
  border-color: #4AC659;
}

.resp-sharing-button--whatsapp:hover,
.resp-sharing-button--whatsapp:active {
  background-color: #1DA851;
  border-color: #1DA851;
}

.social-bar{
  position: fixed;
    bottom: 0;
    width: 100%;
    right: 0;
    z-index: 1;
}