.category{
    > div{
        overflow: hidden;
    }
    .flex-column{
    padding: 1rem 0rem 0rem;
    transition: all 0.3s ease;
    
}
&:hover{
    .flex-column{
    padding-top: 1rem;
    padding-right: 0.5rem;
    padding-left: 0.7rem;
    }
}
}