.rounded{
    border-radius: 24px!important;

    &.rounded{
        border-radius: 13px !important;
    }
}
.rounded-bottom {
    border-bottom-right-radius: 24px!important;
    border-bottom-left-radius: 24px!important;
}

.border-thick{
    border-width: 3px !important;
}

.result-circle-border{
    box-shadow: 0px 0px 10px #F4F4F8;
    border-radius: 50%;
    background-color: white;
    width: 175px;
    height: 175px;
}