body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  * {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    padding: 0;
    background: #F8F8F8;
    font-family: sans-serif;
  }

  h1{
    font-size: 	24px;
  }

  h2{
    font-size:20px
  }
  
  .container {
    position: relative;
    max-width: 1300px;
    margin: 0 auto;
    background: #fff;
    border-radius: 3px;
  }
  
  @media (min-width: 769px) {
    .container {
      margin: 2.5rem auto;
    }
  }

  /* Quiz Component */
  .answerOptions {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  /* QuestionCount Component */
  .questionCount {
    padding: 0rem 0rem 0;
    font-size: 12px;
    margin-top: -3px;
  }
  
  /* Question Component */
  .question {
    margin: 0;
    padding: 0.5rem 2.5rem 1.5rem 2.5rem;
  }
  
  .answerOption:hover {
    cursor: pointer;
    background-color: #FED766;
  }

  .answerOption.correct-answer{
    background: #7EE0AF;
  animation: pulse; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 0.35s; /* don't forget to set a duration! */
  }
  .answerOption.wrong-answer{
    background: #fe4b49;
    animation: headShake; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 0.8s; /* don't forget to set a duration! */
  }
  
  .radioCustomButton {
    position: absolute;
    width: auto;
    opacity: 0;
  }
  
  .radioCustomButton,
  .radioCustomLabel {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }
  
  .radioCustomLabel {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 1rem 3rem 1rem 2.5rem;
    font-size: 16px;
    line-height: 1.5;
    transition: all 0.3s ease-in-out;
    &:hover{
      padding-right: 3.2rem;
    }
  }

  .radioCustomButton ~ .radioCustomLabel:before {
    position: absolute;
    top: 15px;
    right: 10px;
    width: 28px;
    height: 28px;
    content: '';
    display: inline-block;
    vertical-align: middle;
    background: #E6E6EA;
    border: 4px solid #E6E6EA;
    border-radius: 50%;
    transition: all 0.2s;
  }
  
  .radioCustomButton:checked ~ .radioCustomLabel:before {
    content: '';
    background: #2AB7CA ;
    background-size: 27px;
  }


  
  /* Animation */
  .fade-enter {
    opacity: 0;
  }
  
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 0.3s ease-in-out 0.3s;
  }
  
  .fade-leave {
    overflow: hidden;
    position: absolute;
    top: auto;
    right: 25px;
    width: 100%;
    opacity: 1;
  }
  
  .fade-leave.fade-leave-active {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .fade-appear {
    opacity: 0;
  }
  
  .fade-appear.fade-appear-a ctive {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
  

  .extended-description{
    p.collapse[aria-expanded="false"]:not(.show) {
        display: block;
        height: 80px !important;
        overflow: hidden;
    }
    
    p.collapsing[aria-expanded="false"] {
        height: 150px !important;
    }
    a.collapsed:after  {
      content: 'عرض المزيد';
  }
  
  a:not(.collapsed):after {
      content: 'إخفاء';
  }
  }


  .gif-watermark{
    position: relative;
    overflow: visible;
    &:after{
      content:"";
      position: absolute;
      width:35px;
      height:35px;
      background: url('/assets/images/watermark.svg') no-repeat;
      z-index: 1;
      right: 15px;
      bottom:-15px;
    }
  }