// Testing SCSS structure

.bg-primary{
    background: #FE4A49 !important;
}

.bg-secondary{
    background: #fed766 !important;
}

.text-secondary{
    color: #fed766 !important;
}