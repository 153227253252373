.ribbon-up {
    width:max-content;
    margin:auto;
    margin-top:-5px;
    margin-right:5px;
    display: flex;
    flex-wrap:wrap;
    justify-content:center;
    div {
      min-height: 90px;
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-bottom:1em;
      min-width:50px;
      small{
        font-size:11px;
      }
      &::after {
        content: "";
        position: absolute;
        width: 20%;
        padding-top: 20%;
        // box-shadow: 0 0 0 50vw linear-gradient(315deg, rgba(254,182,102,1) 0%, rgba(254,215,102,1) 100%);
        ;
        bottom: 0;
        left: 37.25%;
        transform: rotatez(-45deg) scale(4) translate(-15%, 15%)
          skew(25deg, 25deg);
      }

      > * {
        position: relative;
        z-index: 1;
      }
    }
  }
  
  .ribbon-down {
    width:max-content;
    margin:auto;
    margin-top:-5px;
    margin-left:5px;
    display: flex;
    flex-wrap:wrap;
    justify-content:center;
    div {
      min-height: 90px;
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-bottom:1em;
      min-width:50px;
      small{
        font-size:10px;
      }
     

      > * {
        position: relative;
        z-index: 1;
      }
    }
    }   