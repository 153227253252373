.image {
  position: inherit;
  width: 100%;
  height: 100%;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  }
  .full {
    transition: opacity 300ms ease 0ms;
  }
  .thumb {
    //filter: blur(6px);
    transform: scale(1.1);
    transition: visibility 0ms ease 300ms;
  }