.custom-list-bullets {
    padding-right: 1rem;
    li{
        position: relative;
        margin-bottom: 0.8rem;
    &:before {
        content:"";
        position: absolute;
        right: -18px;
        top: 7px;
        height: 10px;
        width: 10px;
        border-radius: 5px;
        background-color: #FED766;
    }
}
}