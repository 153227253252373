.filter{
    // position: fixed;
    // top:0;
    // width: 100%;
    // right:0;
    // z-index: 1;
    background:#2AB7CA;
    border-radius: 0 0 10px 10px;
    overflow: auto;
    // overflow: hidden scroll;
li{
    margin-left:15px;
}
    .btn{
        box-sizing: border-box;
  height: 40px;
  width: max-content;
  font-size: 14px;
  border: 2px solid #FFFFFF;
  border-radius: 20px;
  box-shadow: 0 0 10px -1px rgba(0,0,0,0.13);
    }
}
.headroom {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;

  // opacity: 0;
}
.headroom-wrapper{
  height: auto !important;
}
.headroom--unfixed {
  position: absolute;
  transform: translateY(-100px);
  opacity: 1;
  margin-top:0rem;
  
}
.headroom.headroom--unfixed{
  position: fixed !important;
  transform: translateY(-100px) !important;
}
.headroom--scrolled {
  transition: all 300ms ease-in-out;
  opacity: 1;
  margin-top:0rem;
}
.headroom--unpinned {
  position: fixed;
  transform: translateY(0) !important;
  
}
.headroom--pinned {
  position: fixed;
  transform: translateY(0%) !important;
}