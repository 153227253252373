.carousel {
    padding: 0 !important;
}

.carousel .rec-slider-container {
    margin: 0;
}
.carousel-card{
    transition: all 0.3s ease;
}
.carousel-card:hover{
    transform: scale(1.03);
}

.carousel-card .card {
    height: 100%;
}


.carousel .rec-item-wrapper {
    height: 100%;
}

.carousel .card-img-top {
    max-height: 140px;
    width: 100%;
}

.slider-container {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    padding: 10px 0;
    margin-bottom: 0;
}

.slider-card {
    flex-shrink: 0;
    width: 100%;
    height: 227px;
    border-radius: 10px;
    margin-left: 5px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}